/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./app/environments/environment";

import { ConstantsUtil } from "./app/talk/utils/constants.util";

setupTheme();

if (environment.production) {
  enableProdMode();
  // eslint-disable-next-line no-console
  console.log = () => {

  };
}

platformBrowserDynamic().bootstrapModule(AppModule);

function setupTheme() {
  const HASH_VALUE = "b5d254360dd4bf6bd372eae8f6d85e7ccd600619";
  localStorage.setItem("themeHash", HASH_VALUE);
  if (environment.isCordova) {
    // loadExternalScript("assets/js/SocialSharing.js");
    loadExternalStyle("assets/css/smooth-scroll.css");
  }

  let currentName = localStorage.getItem(ConstantsUtil.THEME) || environment.theme;
  if (environment.disableThemeOption) {
    currentName = environment.theme;
    localStorage.setItem(ConstantsUtil.THEME, currentName);
  }

  let themePath = `${HASH_VALUE}.`;
  function loadExternalStyle(styleUrl: string, styleId?: string) {
    return new Promise((resolve) => {
      let styleElement = document.createElement("link");
      styleElement.rel = "stylesheet";
      if (styleId) {
        styleElement.id = styleId;
      }
      styleElement.type = "text/css";
      styleElement.href = styleUrl;
      styleElement.onload = function () {
        showApp();
        resolve(true);
      };
      document.head.appendChild(styleElement);
      styleElement = null;
    });
  }

  function showApp() {
    document.querySelector("vp-app").removeAttribute("style");
  }

  document.querySelector("vp-app").setAttribute("style", "display: none");
    if (currentName === "ekbo") {
      loadExternalStyle(themePath + "ekbo.css", "vnctalkTheme").finally(() => { showApp(); });
    } else if (currentName === "airbus") {
      loadExternalStyle(themePath + "airbus.css", "vnctalkTheme").finally(() => { showApp(); });
    } else if (currentName === "hin") {
      loadExternalStyle(themePath + "hin.css", "vnctalkTheme").finally(() => { showApp(); });
    } else {
      loadExternalStyle(themePath + "vnctalk.css", "vnctalkTheme").finally(() => { showApp(); });
    }
  setTimeout(() => {
    showApp();
  }, 2000);
  const fontSize = localStorage.getItem(ConstantsUtil.FONT_SIZE) || "14";

  document.getElementById("main-html").classList.add("font-size-" + fontSize);
  if (environment.isCordova) {
    document.getElementById("main-html").classList.add("is-cordova");

  }

  document.title = environment.title;
}
