<div class="mobile-channel-detail-component" *ngIf="isMobileScreen">
  <div class="mobile-header">
    <vnc-mobile-header
      [headerType]="'channel'"
      [avatarURL]="selectedChannel?.avatar_url"
      [channelName]="selectedChannel?.name"
      [channelDescription]="getPlainDescription(selectedChannel?.description)"
      (channelButtonPress)="channelButtonPress($event)"
      [menuTemplate]="mobilechannelDetailmenuTemplate"
      [isSubscribed]="selectedChannel?.subscribed"
      [rightIcon]="selectedChannel?.channel_type"
      [bgColor]="themeColor"
    ></vnc-mobile-header>
  </div>
  <div class="mobile-search-itemss" [class.is-hide-display-topic-search]="!isDisplayTopicSearch">
    <vnc-mobile-search
      placeholder="{{ 'SEARCH_DOT' | translate }}"
      [showTileIcon]="true"
      [showFilterIcon]="true"
      (tileIconClick)="openTileSelection()"
      (filterClick)="filterClickMobile()"
      [form]="topicSearchControl"
      [showClear]="true"
    ></vnc-mobile-search>
  </div>
  <vnc-banner [type]="'text-close'" class="go-to-parent-banner"  [color]="'green'"
  *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.parent && $showGoToParentChannel | async"
  (click)="gotToParentChannel($event)" (actionClicked)="dismissGotoParentBanner()"
    labelText="{{'GO_BACK_TO_PARENT_CHANNEL' | translate}}"></vnc-banner>
  <div class="restore-banner-wrapper">
    <vnc-banner [type]="'text-3-actions'" class="restore-banner" [actionLinkLabel3]="'UNARCHIVE' | translate | lowercase"
      (actionLink3Clicked)="unarchiveChannel(selectedChannel)" *ngIf="selectedChannel?.archived" [color]="'red'"
      style="margin-bottom: 12px;" (click)="goBack($event)" labelText="{{'ARCHIVED_CHANNEL' | translate}}"></vnc-banner>
    <vnc-banner [type]="'text-3-actions'" class="restore-banner" [actionLinkLabel3]="'RESTORE' | translate | lowercase"
      (actionLink3Clicked)="restoreChannel(selectedChannel)" *ngIf="selectedChannel?.deleted" [color]="'red'"
      style="margin-bottom: 12px;" (click)="goBack($event, 'trash')"
      labelText="{{'TRASHED_CHANNEL' | translate}}"></vnc-banner>
  </div>
  <div *ngIf="showUnarchivedOrRestoredBanner" class="success-restore-banner-wrapper">
    <vnc-banner class="success-restore-banner" [type]="'back-close'"
      (backClicked)="goBack($event, gobackToTrashOrArchive)" [labelText]="this.restoreBannerLabel | translate"
      (linkClicked)="goBack($event, gobackToTrashOrArchive)" [labelText]="this.restoreBannerLabel | translate"
      [color]="'green'">
    </vnc-banner>
  </div>
  <div *ngIf="isShowMobileViewSelection" class="back-drop-select-item"></div>
  <div *ngIf="isShowMobileViewSelection" class="mobile-view-selection-item">
    <div class="left" (click)="changeView(uiTopicView.LIST);closeTileSelection();" [class.mobile-grid-selected]="currentView === 'list' || currentView === 'table'">
      <div class="grid-list-svg">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <defs>
              <filter id="81m404rroa" width="113.9%" height="118%" x="-6.9%" y="-9%" filterUnits="objectBoundingBox">
                  <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"/>
                  <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
                  <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0"/>
                  <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter2"/>
                  <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"/>
                  <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.1 0"/>
                  <feMerge>
                      <feMergeNode in="shadowMatrixOuter1"/>
                      <feMergeNode in="shadowMatrixOuter2"/>
                      <feMergeNode in="SourceGraphic"/>
                  </feMerge>
              </filter>
          </defs>
          <g fill="none" fill-rule="evenodd">
              <g fill="#8B96A0" filter="url(#81m404rroa)" transform="translate(-16 -180)">
                  <g>
                      <g>
                          <g>
                              <path d="M16 10c.552 0 1-.443 1-1.01V7.01C17 6.451 16.545 6 16 6H1c-.552 0-1 .443-1 1.01v1.98C0 9.549.455 10 1 10h15zM0 2.99C0 3.549.455 4 1 4h15c.552 0 1-.443 1-1.01V1.01C17 .451 16.545 0 16 0H1C.448 0 0 .443 0 1.01v1.98z" transform="translate(0 166) translate(16 14) translate(4 7)"/>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
        <span class="lbl">{{ 'TILE_VIEW' | translate }}</span>
      </div>
      <div>
        <span *ngIf="currentView === 'list' || currentView === 'table'">
          <svg _ngcontent-jcy-c30="" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg" class="ng-star-inserted"><g _ngcontent-jcy-c30="" fill="none" fill-rule="evenodd"><g _ngcontent-jcy-c30="" fill="#337CBD"><g _ngcontent-jcy-c30=""><g _ngcontent-jcy-c30=""><g _ngcontent-jcy-c30=""><g _ngcontent-jcy-c30=""><path _ngcontent-jcy-c30="" d="M4.5 8.377L1.373 5.25 0.307 6.308 4.5 10.5 13.5 1.5 12.443 0.443z" transform="translate(-447 -105) translate(14 96) translate(430 6) translate(3 3) translate(2.25 3.75)"></path></g></g></g></g></g></g></svg>
        </span>
        <!-- <vp-dropdown-item itemType="subheader-icon" label="{{ 'TILE_VIEW' | translate }}" [sufixImageType]="currentView === 'list' ? 'check' : ''" (onClickEvent)="changeView(uiTopicView.LIST);closeTileSelection();"></vp-dropdown-item> -->
      </div>
    </div>
    <div class="right" (click)="changeView(uiTopicView.GRID);closeTileSelection();" [class.mobile-list-selected]="currentView === 'grid'">
      <div class="grid-list-svg">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
<style type="text/css">
	.st0{filter:url(#filter-1);}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#8B96A0;}
</style>
<filter  filterUnits="objectBoundingBox" height="118.0%" id="filter-1" width="113.9%" x="-6.9%" y="-9.0%">
	<feOffset  dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
	<feGaussianBlur  in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"></feGaussianBlur>

		<feColorMatrix  in="shadowBlurOuter1" result="shadowMatrixOuter1" type="matrix" values="0 0 0 0 0.027713168   0 0 0 0 0.114145307   0 0 0 0 0.200577446  0 0 0 0.2 0">
		</feColorMatrix>
	<feOffset  dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
	<feGaussianBlur  in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1"></feGaussianBlur>

		<feColorMatrix  in="shadowBlurOuter2" result="shadowMatrixOuter2" type="matrix" values="0 0 0 0 0.027713168   0 0 0 0 0.114145307   0 0 0 0 0.200577446  0 0 0 0.1 0">
		</feColorMatrix>
	<feMerge>
		<feMergeNode  in="shadowMatrixOuter1"></feMergeNode>
		<feMergeNode  in="shadowMatrixOuter2"></feMergeNode>
		<feMergeNode  in="SourceGraphic"></feMergeNode>
	</feMerge>
</filter>
<g id="Channels">
	<g id="Group" transform="translate(4.000000, -212.000000)" class="st0">
		<g id="_x30_0---Mobile_x2F_dropdown_x2F_menu_x2F_grid_1_" transform="translate(0.000000, 166.000000)">
			<g id="_x30_0-Mobile_x2F_dropdown_x2F_item_x2F__x40_l_x2F_icon-drop-default_1_" transform="translate(0.000000, 52.000000)">
				<g id="Container_x2F_24px_1_" transform="translate(16.000000, 14.000000)">
					<g id="Icon-24px_1_" transform="translate(3.000000, 7.000000)">
						<path id="Shape_1_" class="st1" d="M-20-14h2v-2h-2V-14L-20-14z M-20-10h2v-2h-2V-10L-20-10z M-20-18h2v-2h-2V-18L-20-18z
							 M-16-14h14v-2h-14V-14L-16-14z M-16-10h14v-2h-14V-10L-16-10z M-16-20v2h14v-2H-16L-16-20z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
</svg>
        <span class="lbl">{{ 'LIST_VIEW' | translate }}</span>
      </div>
      <div>
        <span *ngIf="currentView === 'grid'">
          <svg _ngcontent-jcy-c30="" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg" class="ng-star-inserted"><g _ngcontent-jcy-c30="" fill="none" fill-rule="evenodd"><g _ngcontent-jcy-c30="" fill="#337CBD"><g _ngcontent-jcy-c30=""><g _ngcontent-jcy-c30=""><g _ngcontent-jcy-c30=""><g _ngcontent-jcy-c30=""><path _ngcontent-jcy-c30="" d="M4.5 8.377L1.373 5.25 0.307 6.308 4.5 10.5 13.5 1.5 12.443 0.443z" transform="translate(-447 -105) translate(14 96) translate(430 6) translate(3 3) translate(2.25 3.75)"></path></g></g></g></g></g></g></svg>
        </span>
        <!-- <vp-dropdown-item itemType="subheader-icon" label="{{ 'LIST_VIEW' | translate }}" [sufixImageType]="currentView === 'grid' ? 'check' : ''" (onClickEvent)="changeView(uiTopicView.GRID);closeTileSelection();"></vp-dropdown-item> -->
      </div>
    </div>
  </div>
  <vp-topic-list [isMobileScreen]="isMobileScreen"
                 [topics]="topics"
                 [unpublishedTopics]="unpublishedTopics"
                 [searchedTopics]="searchedTopics"
                 [filteredTopics]="filteredTopics$ | async"
                 [topicsGroupedByAuthor]="topicsGroupedByAuthor"
                 [authorsGroup]="authorsGroup"
                 [currentView]="currentView"
                 [heroImageHeight]="heroImageHeight"
                 [wrapperWidth]="wrapperWidth"
                 [isTopicsLoading]="isTopicsLoading"
                 [selectedChannel]="selectedChannel"
                 [isFiltersApplied]="isFiltersApplied"
                 [filteredTopicsLoaded]="filteredTopicsLoaded"
                 [filteredTopicsLoading]="filteredTopicsLoading"
                 [isRightBarExpanded]="isRightBarExpanded"
                 [isLeftBarExpanded]="isLeftBarExpanded"
                 [isDisplayTopicSearch]="isDisplayTopicSearch"
                 [emptyCards]="emptyCards"
                 [emptyStateBackground]="emptyStateBackground"
                 [emptyStateType]="emptyStateType"
                 [groupBy]="groupBy"
                 [noAccess]="noAccess"
                 [topicSearchControl]="topicSearchControl"
                 [emptyStateTypeNoAccess]="emptyStateTypeNoAccess"
                 (loadMoreTopics)="loadMoreTopics($event)"
                 (loadMoreFilteredTopics)="loadMoreFilteredTopics()"
                 (addFileToTopic)="addFileToTopic($event.files, $event.item)"
                 (requestAccess)="requestAccess()"
                 (swipe)="swipe($event.event, $event.action)">
  </vp-topic-list>
</div>


<div class="channel-detail__wrapper desktop-channel-detail-component"
     *ngIf="!isMobileScreen" id="channelDetailWrapper" #channelDetailWrapper>
  <div *ngIf="noAccess" class="empty-state no-access"  [ngStyle]="emptyStateBackground">
    <vnc-empty-state [iconTemplate]="emptyStateIcon" [selectedChannel]="channel"
                     title="{{ 'YOU_NEED_PERMISSION' | translate }}" description="{{ 'YOU_NEED_PERMISSION_CHANNEL_DESC' | translate }}" buttonText="{{ 'REQUEST_ACCESS' | translate }}" type="{{ emptyStateTypeNoAccess }}" (buttonClicked)="requestAccess()"></vnc-empty-state>
  </div>
  <div *ngIf="!noAccess" class="channel-detail__header">
    <div class="channel-detail__header__left">
      <vnc-avatar [imgSrc]="selectedChannel?.avatar_url"
                  [leftIcon]="selectedChannel?.subscribed ? 'subscribe' : ''"
                  [rightIcon]="selectedChannel?.channel_type"
                  [defaultAvatar]="selectedChannel?.default_cover_url ?? defaultChannelAvatar"

      ></vnc-avatar>
      <div class="channel-info">
        <div class="channel-name">
          {{ selectedChannel?.name }}
        </div>
        <div *ngIf="selectedChannel?.description" class="channel-description">
          {{ getPlainDescription(selectedChannel?.description) }}
        </div>
      </div>
    </div>
    <div class="channel-detail__header__middle">

    </div>
    <div class="channel-detail__header__right">
      <button class="kebab-menu-btn" mat-icon-button
              [matMenuTriggerFor]="viewMenu">
              <vnc-action-panel *ngIf="currentView === 'grid'" [iconTemplate]="gridIcon" [type]="'action'" [actionColor]="'white'" matTooltip="{{ 'GRID_VIEW' | translate }}" matTooltipPosition="below"></vnc-action-panel>
              <vnc-action-panel *ngIf="currentView === 'list'" [iconTemplate]="listIcon" [type]="'action'" [actionColor]="'white'" matTooltip="{{ 'GRID_VIEW' | translate }}" matTooltipPosition="below"></vnc-action-panel>
              <vnc-action-panel *ngIf="currentView === 'table'" [iconTemplate]="tableIcon" [type]="'action'" [actionColor]="'white'" matTooltip="{{ 'GRID_VIEW' | translate }}" matTooltipPosition="below"></vnc-action-panel>
      </button>
      <mat-menu class="vnc-list-item-menu menu-width-240 vnc-detail-list-desktop-menu" #viewMenu="matMenu">
        <button mat-menu-item (click)="changeView(uiTopicView.GRID)" [class.active]="currentView === 'grid'" class="topic-view">
          <ng-container *ngTemplateOutlet="gridIcon">
          </ng-container>
          <span style="margin-right: auto">{{ 'GRID' | translate }}</span>
          <ng-container *ngIf="currentView === 'grid'">
            <ng-container *ngTemplateOutlet="selectedIcon">
            </ng-container>
          </ng-container>
        </button>
        <button mat-menu-item (click)="changeView(uiTopicView.LIST)" [class.active]="currentView === 'list'" class="topic-view">
          <ng-container *ngTemplateOutlet="listIcon">
          </ng-container>
          <span style="margin-right: auto">{{ 'TILE' | translate }}</span>
          <ng-container *ngIf="currentView === 'list'">
            <ng-container *ngTemplateOutlet="selectedIcon">
            </ng-container>
          </ng-container>
        </button>
        <button mat-menu-item (click)="changeView(uiTopicView.TABLE)" [class.active]="currentView === 'table'" class="topic-view">
          <ng-container *ngTemplateOutlet="tableIcon">
          </ng-container>
          <span style="margin-right: auto">{{ 'TABLE' | translate }}</span>
          <ng-container *ngIf="currentView === 'table'">
            <ng-container *ngTemplateOutlet="selectedIcon">
            </ng-container>
          </ng-container>
        </button>
      </mat-menu>
      <!-- <button class="kebab-menu-btn" mat-icon-button
      [matMenuTriggerFor]="menu">
      <mat-icon class="kebab-menu-icon"
      fontSet="mdi" fontIcon="mdi-dots-vertical" matTooltip="{{ 'MORE_TEXT' | translate }}" matTooltipPosition="below" ></mat-icon>
    </button> -->
    <vnc-action-panel [matMenuTriggerFor]="menu" [iconTemplate]="moreIcon" [type]="'action'" [actionColor]="'white'" matTooltip="{{ 'MORE_TEXT' | translate }}" matTooltipPosition="below" matTooltipClass="customise-tooltip"></vnc-action-panel>
      <mat-menu class="vnc-list-item-menu menu-width-240 vnc-detail-list-desktop-menu" #menu="matMenu">

        <div class="item-drop-down">
          <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="showChannelInfo(selectedChannel)" [prefixIconTemplate]="infoIconTemplate" label="{{'CHANNEL_INFO' | translate }}">
          </vp-dropdown-item>
        </div>
        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted">
          <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="openHeirarchy(selectedChannel)" [prefixIconTemplate]="openInBrowser" label="{{'OPEN_IN_BROWSER' | translate }}">
          </vp-dropdown-item>
        </div>
        <!-- for desktop top right corner kebab menu -->
        <div class="item-drop-down" *ngIf="!selectedChannel?.is_iom &&
                                    checkPermission(selectedChannel) &&
                                    !selectedChannel?.archived &&
                                    !selectedChannel?.deleted">
          <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="addSubchannel(selectedChannel)" [prefixIconTemplate]="addSubchannelImage" label="{{'SUBCHANNEL_CREATE' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && !selectedChannel?.pinned">
          <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="pinChannel(selectedChannel)" [prefixIconTemplate]="pinIcon" label="{{'PIN_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.pinned">
          <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="unpinChannel(selectedChannel)" [prefixIconTemplate]="unPinIcon" label="{{'UNPIN_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.favorited && !selectedChannel?.deleted && !selectedChannel?.archived">
          <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="addChannelToFavorites(selectedChannel)" [prefixIconTemplate]="addToFavoritesIcon" label="{{'ADD_TO_FAVORITES' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="selectedChannel?.favorited && !selectedChannel?.deleted && !selectedChannel?.archived">
          <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="removeChannelFromFavorites(selectedChannel)" [prefixIconTemplate]="removeFromFavoritesIcon" label="{{'REMOVE_FROM_FAVORITES' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.permissions?.length > 0 && selectedChannel?.permissions[1] && selectedChannel?.permissions[1]?.moderate_channel">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="editChannel(selectedChannel)" [prefixIconTemplate]="editIcon" label="{{'EDIT_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="copyChannelLink(selectedChannel)" [prefixIconTemplate]="copyLinkIcon" label="{{'COPY_LINK' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && !selectedChannel?.subscribed">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="subscribeChannel(selectedChannel)" [prefixIconTemplate]="subscribeIcon" label="{{'SUBSCRIBE_TO_THE_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.subscribed">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="unsubscribeChannel(selectedChannel)" [prefixIconTemplate]="subscribeIcon" label="{{'UNSUBSCRIBE_FROM_THE_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && !selectedChannel?.muted">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="muteChannel(selectedChannel)" [prefixIconTemplate]="muteIcon" label="{{'MUTE_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.muted">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="unmuteChannel(selectedChannel)" [prefixIconTemplate]="unMuteIcon" label="{{'UNMUTE_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="openSendChannelLinkPopup(selectedChannel)" [prefixIconTemplate]="sendToIcon" label="{{'SEND_TO_DOT' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.permissions?.length > 0 && selectedChannel?.permissions[1] && selectedChannel?.permissions[1]?.moderate_channel">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="archiveChannel(selectedChannel)" [prefixIconTemplate]="archiveIcon" label="{{'ARCHIVE_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.permissions?.length > 0 && selectedChannel?.permissions[1] && selectedChannel?.permissions[1]?.moderate_channel">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="unarchiveChannel(selectedChannel)" [prefixIconTemplate]="unArchiveIcon" label="{{'UNARCHIVE_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.deleted && selectedChannel?.permissions?.length > 0 && selectedChannel?.permissions[1] && selectedChannel?.permissions[1]?.moderate_channel &&  !selectedChannel?.archived">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="deleteChannel(selectedChannel, true)" [prefixIconTemplate]="deleteIcon" label="{{'DELETE_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="!selectedChannel?.deleted && selectedChannel?.permissions?.length > 0 && selectedChannel?.permissions[1] && selectedChannel?.permissions[1]?.moderate_channel && selectedChannel?.archived">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="deleteChannel(selectedChannel)" [prefixIconTemplate]="deleteIcon" label="{{'DELETE_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>


        <div class="item-drop-down" *ngIf="selectedChannel?.deleted && selectedChannel?.permissions && getPermission(selectedChannel?.permissions, 'moderate_channel')">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="restoreChannel(selectedChannel)" [prefixIconTemplate]="restoreIcon" label="{{'RESTORE_CHANNEL' | translate }}">
          </vp-dropdown-item>
        </div>

        <div class="item-drop-down" *ngIf="selectedChannel?.deleted && selectedChannel?.permissions && getPermission(selectedChannel?.permissions, 'moderate_channel')">
          <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="deleteChannel(selectedChannel)" [prefixIconTemplate]="deleteIcon" label="{{'DELETE_CHANNEL_COMPLETELY' | translate }}">
          </vp-dropdown-item>
        </div>

      </mat-menu>
    </div>
  </div>
  <vnc-banner [type]="'back-close'"
              class="archive-alert unclosable-banner"
              *ngIf="selectedChannel?.archived && !selectedChannel?.deleted"
              style="margin-bottom: 12px;"
              (backClicked)="goBack($event)"
              labelText="{{'ARCHIVED_CHANNEL' | translate}}"></vnc-banner>
  <vnc-banner [type]="'back-close'"
              class="archive-alert unclosable-banner"
              *ngIf="selectedChannel?.deleted"
              style="margin-bottom: 12px;"
              (backClicked)="goBack($event, 'trash')"
              labelText="{{'TRASHED_CHANNEL' | translate}}"></vnc-banner>
  <!-- <div class="restore-banner-wrapper">
    <vnc-banner [type]="'text-3-actions'" class="restore-banner" [actionLinkLabel3]="'UNARCHIVE' | translate | lowercase"
      (actionLink3Clicked)="unarchiveChannel(selectedChannel)" *ngIf="selectedChannel?.archived" [color]="'red'"
      style="margin-bottom: 12px;" (click)="goBack($event)" labelText="{{'ARCHIVED_CHANNEL' | translate}}"></vnc-banner>
    <vnc-banner [type]="'text-3-actions'" class="restore-banner" [actionLinkLabel3]="'RESTORE' | translate | lowercase"
      (actionLink3Clicked)="restoreChannel(selectedChannel)" *ngIf="selectedChannel?.deleted" [color]="'red'"
      style="margin-bottom: 12px;" (click)="goBack($event, 'trash')"
      labelText="{{'TRASHED_CHANNEL' | translate}}"></vnc-banner>
  </div> -->

              <!-- goto parent channel if have a channel -->
  <vnc-banner [type]="'text-close'"
              class="go-to-parent-banner"
              *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.parent && $showGoToParentChannel | async"
              [color]="'green'"
              (click)="gotToParentChannel($event)"
              (actionClicked)="dismissGotoParentBanner()"
              labelText="{{'GO_BACK_TO_PARENT_CHANNEL' | translate}}"></vnc-banner>

  <div *ngIf="!noAccess" [class.emptyState]="topics && topics.length === 0" class="channel-detail__topics">
    <vnc-banner *ngIf="isUploadRunning" [type]="'text-loader'"
                labelText="{{totalFilesUploading}} {{(totalFilesUploading > 1 ? 'FILES_UPLOADING' : 'FILE_UPLOADING') | translate}}"
                style="width:100%; position: absolute; top: 0; z-index: 10;"></vnc-banner>
    <vnc-banner (actionClicked)="dismissSuccessBanner()"
                (click)="showNewUploadedFiles()" *ngIf="!isUploadRunning && showSuccessBanner"
                [color]="'green'" [labelText]="'FILES_UPLOADED' | translate" [type]="'text-close'"
                style="width:100%; position: absolute; top: 0; z-index: 10;"></vnc-banner>

                <vp-topic-list class="desktop-topic-list" [isMobileScreen]="isMobileScreen" [topics]="topics"
                  [unpublishedTopics]="unpublishedTopics" [filteredTopics]="filteredTopics$ | async"
                  [topicsGroupedByAuthor]="topicsGroupedByAuthor" [authorsGroup]="authorsGroup" [currentView]="currentView"
                  [heroImageHeight]="heroImageHeight" [wrapperWidth]="wrapperWidth" [isTopicsLoading]="isTopicsLoading"
                  [selectedChannel]="selectedChannel" [isFiltersApplied]="isFiltersApplied"
                  [filteredTopicsLoaded]="filteredTopicsLoaded" [filteredTopicsLoading]="filteredTopicsLoading"
                  [isRightBarExpanded]="isRightBarExpanded" [isLeftBarExpanded]="isLeftBarExpanded" [emptyCards]="emptyCards"
                  [emptyStateBackground]="emptyStateBackground" [emptyStateType]="emptyStateType" [groupBy]="groupBy" [sortBy]="sortBy"
                  (loadMoreTopics)="loadMoreTopics($event)" (loadMoreFilteredTopics)="loadMoreFilteredTopics()"
                  (addFileToTopic)="addFileToTopic($event.files, $event.item)">
                </vp-topic-list>
</div>
</div>

<vnc-floating-button [iconTemplate]="plusIcon" *ngIf="isMobileScreen && (!selectedChannel?.archived) && (topicSearchControl.value === '') && getPermission(selectedChannel?.permissions, 'create_topics') && !getPermission(selectedChannel?.permissions, 'read_only')" id="newConversationMobile"
(onClick)="channelDetailService.createTopic('regular');" size="m" backgroundColor="blue600"></vnc-floating-button>
  <ng-template #mobilechannelDetailmenuTemplate>

    <div class="item-drop-down">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="showChannelInfo(selectedChannel)" [prefixIconTemplate]="infoIconTemplate" label="{{'CHANNEL_INFO' | translate }}">
        </vp-dropdown-item>
      </div>


      <div class="item-drop-down" *ngIf="!selectedChannel?.is_iom && !selectedChannel?.archived && !selectedChannel?.deleted && checkPermission(selectedChannel)">
        <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="addSubchannel(selectedChannel)" [prefixIconTemplate]="addSubchannelImage" label="{{'SUBCHANNEL_CREATE' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && !selectedChannel?.pinned">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="pinChannel(selectedChannel)" [prefixIconTemplate]="pinIcon" label="{{'PIN_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.pinned">
        <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="unpinChannel(selectedChannel)" [prefixIconTemplate]="unPinIcon" label="{{'UNPIN_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && !selectedChannel?.favorited && !selectedChannel?.deleted">
      <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="addChannelToFavorites(selectedChannel)" [prefixIconTemplate]="addToFavoritesIcon" label="{{'ADD_TO_FAVORITES' | translate }}">
      </vp-dropdown-item>
    </div>

    <div class="item-drop-down" *ngIf="selectedChannel?.favorited && !selectedChannel?.deleted">
      <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="removeChannelFromFavorites(selectedChannel)" [prefixIconTemplate]="removeFromFavoritesIcon" label="{{'REMOVE_FROM_FAVORITES' | translate }}">
      </vp-dropdown-item>
    </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && getPermission(selectedChannel?.permissions, 'moderate_channel') && !getPermission(selectedChannel?.permissions, 'read_only')">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="editChannel(selectedChannel)" [prefixIconTemplate]="editIcon" label="{{'EDIT_CHANNEL' | translate }}">
        </vp-dropdown-item>
    </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted">
        <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="copyChannelLink(selectedChannel)" [prefixIconTemplate]="copyLinkIcon" label="{{'COPY_LINK' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && !selectedChannel?.subscribed">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="subscribeChannel(selectedChannel)" [prefixIconTemplate]="subscribeIcon" label="{{'SUBSCRIBE_TO_THE_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.subscribed">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="unsubscribeChannel(selectedChannel)" [prefixIconTemplate]="subscribeIcon" label="{{'UNSUBSCRIBE_FROM_THE_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && !selectedChannel?.muted">
        <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="muteChannel(selectedChannel)" [prefixIconTemplate]="muteIcon" label="{{'MUTE_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && selectedChannel?.muted">
        <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="unmuteChannel(selectedChannel)" [prefixIconTemplate]="unMuteIcon" label="{{'UNMUTE_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="openSendChannelLinkPopup(selectedChannel)" [prefixIconTemplate]="sendToIcon" label="{{'SEND_TO_DOT' | translate }}">
        </vp-dropdown-item>
    </div>

    <div class="item-drop-down" *ngIf="isCordovaApp && !selectedChannel?.deleted">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="channelDetailService.shareChannel(selectedChannel)" [prefixIconTemplate]="shareIcon" label="{{'SHARE' | translate }}">
        </vp-dropdown-item>
      </div>
    <hr style="width: 95vw;margin: 1em auto;" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && getPermission(selectedChannel?.permissions, 'moderate_channel') && !getPermission(selectedChannel?.permissions, 'read_only') && isMobileScreen" />

    <div class="item-drop-down" *ngIf="!selectedChannel?.archived && !selectedChannel?.deleted && getPermission(selectedChannel?.permissions, 'moderate_channel')">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="archiveChannel(selectedChannel)" [prefixIconTemplate]="archiveIcon" label="{{'ARCHIVE_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="selectedChannel?.archived && !selectedChannel?.deleted && getPermission(selectedChannel?.permissions, 'moderate_channel') && !getPermission(selectedChannel?.permissions, 'read_only')">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="unarchiveChannel(selectedChannel)" [prefixIconTemplate]="unArchiveIcon" label="{{'UNARCHIVE_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="!selectedChannel?.deleted && selectedChannel?.permissions && getPermission(selectedChannel?.permissions, 'moderate_channel') && !getPermission(selectedChannel?.permissions, 'read_only')">
        <vp-dropdown-item [itemType]="'icon-drop-default'" (onClickEvent)="deleteChannel(selectedChannel)" [prefixIconTemplate]="deleteIcon" label="{{'DELETE_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="selectedChannel?.deleted && selectedChannel?.permissions && getPermission(selectedChannel?.permissions, 'moderate_channel') && !getPermission(selectedChannel?.permissions, 'read_only')">
        <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="restoreChannel(selectedChannel)" [prefixIconTemplate]="restoreIcon" label="{{'RESTORE_CHANNEL' | translate }}">
        </vp-dropdown-item>
      </div>

    <div class="item-drop-down" *ngIf="selectedChannel?.deleted && selectedChannel?.permissions && getPermission(selectedChannel?.permissions, 'moderate_channel') && !getPermission(selectedChannel?.permissions, 'read_only')">
      <vp-dropdown-item [itemType]="'icon-drop-default'"  (onClickEvent)="deleteChannel(selectedChannel)" [prefixIconTemplate]="deleteIcon" label="{{'DELETE_CHANNEL_COMPLETELY' | translate }}">
      </vp-dropdown-item>
    </div>
  </ng-template>

<ng-template #plusIcon>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <filter x="-198.2%" y="-198.2%" width="496.4%" height="496.4%" filterUnits="objectBoundingBox" id="a">
            <feOffset dy="16" in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation="12" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
            <feOffset dy="12" in="SourceAlpha" result="shadowOffsetOuter2"/>
            <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
            <feColorMatrix values="0 0 0 0 0.027713168 0 0 0 0 0.114145307 0 0 0 0 0.200577446 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
            <feMerge>
                <feMergeNode in="shadowMatrixOuter1"/>
                <feMergeNode in="shadowMatrixOuter2"/>
                <feMergeNode in="SourceGraphic"/>
            </feMerge>
        </filter>
    </defs>
    <g transform="translate(-16 -16)" filter="url(#a)" fill="#FFF" fill-rule="evenodd">
        <path d="M35 29h-6v6h-2v-6h-6v-2h6v-6h2v6h6z"/>
    </g>
</svg>
</ng-template>
<ng-template #infoIconTemplate>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.INFO_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #openInBrowser>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.OPEN_IN_BROWSER"></vp-channel-detail-icon>
</ng-template>
<ng-template #addSubchannelImage>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.ADD_SUBCHANNEL"></vp-channel-detail-icon>
</ng-template>

<ng-template #deleteIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.DELETE_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #archiveIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.ARCHIVE_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #unArchiveIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.UN_ARCHIVE_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #sendToIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.SEND_TO_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #subscribeIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.SUBSCRIBE_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #copyLinkIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.COPY_LINK_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #editIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.EDIT_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #shareIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.SHARE_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #muteIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.MUTE_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #unMuteIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.UNMUTE_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #pinIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.PIN_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #unPinIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.UNPIN_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #addToFavoritesIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.ADD_TO_FAVORITES_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #removeFromFavoritesIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.REMOVE_FROM_FAVORITES_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #selectedIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.SELECTED_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #gridIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.GRID_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #tableIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.TABLE_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #listIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.LIST_ICON"></vp-channel-detail-icon>
</ng-template>

<ng-template #emptyStateIcon>
  <vp-channel-detail-icon [iconName]="channelDetailIconName.EMPTY_STATE_ICON"></vp-channel-detail-icon>
</ng-template>
<ng-template #restoreIcon>
  <svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g fill="#8B96A0"><g><g><g><g><path d="M13.578 3H14c.95 0 1.689.8 1.746 1.77l.004.123v9.633c0 1.018-.688 1.9-1.63 1.97L14 16.5H4c-.964 0-1.69-.842-1.746-1.848l-.004-.126V4.893c0-.984.7-1.823 1.632-1.889L4 3h.422c-.263.458-.351.991-.265 1.5H4c-.101 0-.22.123-.245.317l-.005.076v1.535h3v1.5h-3v6.598c0 .226.09.387.178.448l.037.02L4 15h10c.09 0 .217-.144.245-.381l.005-.093V7.928h-2.993v-1.5h2.993V4.893c0-.185-.082-.32-.172-.37l-.04-.017L14 4.5h-.157c.086-.509-.002-1.041-.265-1.5zM9 .385l3.157 3.202c.29.295.29.772 0 1.066-.291.295-.763.295-1.054 0L9.757 3.287v6.415c0 .411-.337.745-.753.745-.417 0-.754-.334-.754-.745V3.279L6.897 4.654c-.29.295-.763.295-1.054 0-.29-.294-.29-.771 0-1.066L9 .386z" transform="translate(-99 -138) translate(16 131) translate(76) translate(4 4) translate(3 3)"></path></g></g></g></g></g></g></svg>
</ng-template>
<ng-template #moreIcon>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 6c.825 0 1.5-.675 1.5-1.5S9.825 3 9 3s-1.5.675-1.5 1.5S8.175 6 9 6zm0 1.5c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5 1.5-.675 1.5-1.5S9.825 7.5 9 7.5zM9 12c-.825 0-1.5.675-1.5 1.5S8.175 15 9 15s1.5-.675 1.5-1.5S9.825 12 9 12z" fill="#8B96A0" fill-rule="evenodd"/>
  </svg>
</ng-template>
